<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container fluid grid-list-md pa-0>
        <v-row >
          <v-col cols="6">
            <v-text-field
                v-model="filtro"
                clearable
                :label="$t('label.pesquisa_filtro_apuracao_acao')"
                @input="filtrar"
                solo>
              <template v-slot:append>
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-width="1000"
                    :nudge-left="1000"
                    :max-width="1000"
                    offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on">
                        <v-badge
                        bordered
                        color="orange"
                        icon="mdi-lock"
                        content=" "
                        v-if="habilitaIconBadge">
                        <v-icon>filter_list</v-icon>
                      </v-badge>
                        <v-icon v-else>filter_list</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-menu
                            ref="dataMenu"
                            v-model="dataMenu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="dataFormatada"
                                :label="`${$tc('label.mes', 1)} *`"
                                prepend-icon="event"
                                :rules="[rules.requiredLista]"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              locale="pt-br"
                              v-model="date"
                              type="month"
                              multiple
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="dataMenu = false"
                              >
                                {{ $t('label.cancelar') }}
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dataMenu.save(date)"
                              >
                                {{ $t('label.ok') }}
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-autocomplete
                            v-model="fornecedores"
                            :items="itemsFornecedor"
                            :label="`${$tc('label.fornecedor', 1)}`"
                            clearable
                            item-value="shortCode"
                            multiple
                            chips
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-autocomplete
                            v-model="tiposCalculo"
                            :items="itemsTipoCalculo"
                            :label="`${$tc('label.tipo_calculo', 1)}`"
                            multiple
                            clearable
                            item-value="shortCode"
                            chips
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-autocomplete
                            v-model="status"
                            :items="itemsStatus"
                            :label="`${$tc('label.status', 1)}`"
                            multiple
                            item-value="shortCode"
                            clearable
                            chips
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-autocomplete
                            v-model="tiposAcao"
                            :items="itemsTipoAcao"
                            :label="`${$tc('label.tipo_acao', 1)}`"
                            multiple
                            item-value="shortCode"
                            clearable
                            chips
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-autocomplete
                            v-model="tiposUso"
                            :items="itemsTipoUso"
                            :label="`${$tc('label.tipo_uso', 1)}`"
                            multiple
                            clearable
                            chips
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-autocomplete
                            v-model="descricaoPassoApuracaoSelecionadas"
                            :items="descricaoPasso"
                            :label="`${$tc('label.descricao_passo', 1)}`"
                            multiple
                            item-value="text"
                            clearable
                            chips
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-autocomplete
                            v-model="codigoCampanhasSelecionadas"
                            :items="codigoCampanhas"
                            :label="`${$tc('label.codigo_campanha_acao', 1)}`"
                            multiple
                            item-value="text"
                            clearable
                            chips
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-switch
                            dense
                            id="config_acao_vigencia_trimestral"
                            :label="$t('label.filtro_acoes_com_teto')"
                            v-model="indAcoesComTeto"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          @click="menu = false"
                      >
                        {{ $t('label.cancelar') }}
                      </v-btn>
                      <v-btn
                          color="primary"
                          text
                          @click="pesquisar"
                      >
                        {{ $t('label.pesquisar') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-switch
              :label="$t('label.minhas_nds_pendentes')"
              v-model="indAcoesComNdsPendentes"
              @change="verificaStatusWorkspace($event)"
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { skipLoading } from '../../common/functions/loading';

export default {
  name: 'ApuracaoAcaoFiltro',
  props: {
    aplicarFiltroNoCarregamento: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      extensaoResources: this.$api.extensao(this.$resource),
      tipoUsoResources: this.$api.tipoUso(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      dataMenu: false,
      date: [],
      dateFormat: [],
      menu: false,
      modal: false,
      itemsFornecedor: [],
      fornecedores: [],
      itemsTipoCalculo: [
        { text: this.$tc('label.percentual', 1), shortCode: 'PERCENTUAL' },
        { text: this.$tc('label.valor_fixo', 1), shortCode: 'FIXO' },
        { text: this.$tc('label.valor_por_peca', 1), shortCode: 'VALOR_POR_PECA' },
      ],
      tiposCalculo: [],
      itemsStatus: [
        { shortCode: 'APURACAO_PREVIA', text: this.$tc('status_entidade.apuracao_previa', 1) },
        { shortCode: 'AGUARDANDO_APURACAO', text: this.$tc('status_entidade.aguardando_apuracao', 1) },
        { shortCode: 'AGUARDANDO_APROVACAO', text: this.$tc('status_entidade.aguardando_aprovacao', 1) },
        { shortCode: 'APROVADO', text: this.$tc('status_entidade.aprovado', 1) },
        { shortCode: 'CANCELADO', text: this.$tc('status_entidade.cancelado', 1) },
        { shortCode: 'EM_ANALISE', text: this.$tc('status_entidade.em_analise', 1) },
        { shortCode: 'REPROVADO', text: this.$tc('status_entidade.reprovado', 1) },
      ],
      status: [],
      itemsTipoAcao: [],
      tiposAcao: [],
      itemsTipoUso: [],
      tiposUso: [],
      filtro: '',
      filtrosVerificacao: [],
      timeout: null,
      indAcoesComTeto: false,
      indAcoesComNdsPendentes: false,
      codigoCampanhas: [],
      codigoCampanhasSelecionadas: [],
      descricaoPasso: [],
      descricaoPassoApuracaoSelecionadas: [],
      workspace: {},
      habilitaIconBadge: false,
      countFiltro: 0,
      filtros: {},
      countFiltrosPreenchidos: 0,
      statusPreSelecionado: ['AGUARDANDO_APURACAO', 'APURACAO_PREVIA', 'AGUARDANDO_APROVACAO', 'EM_ANALISE'],
      inicializacaoComponente: false,
      rules: {
        requiredLista: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    dataFormatada: {
      set() {},
      get() {
        if (!this.date) {
          return '';
        }
        this.dateFormat = [];
        this.date.forEach((data) => {
          const dtFormat = moment(data, 'YYYY-MM').format('MM/YYYY');
          this.dateFormat.push(dtFormat);
        });
        return this.dateFormat;
      },
    },
  },
  methods: {
    filtrar() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.pesquisar();
      }, 500);
    },
    pesquisar() {
      if (!this.$refs.form.validate()) return;
      if (this.indAcoesComNdsPendentes && this.status && this.status.length === this.statusPreSelecionado.length
          && this.status.every((element, index) => element === this.statusPreSelecionado[index])) {
        this.filtros = {
          status: this.statusPreSelecionado,
          idTipoAcao: this.tiposAcao,
          filtro: this.filtro,
          nomesTiposUso: this.tiposUso,
          idGrupoFornecedor: this.fornecedores,
          tiposCalculo: this.tiposCalculo,
          meses: this.date,
          indAcoesComTeto: this.indAcoesComTeto ? true : null,
          codigoCampanhas: this.codigoCampanhasSelecionadas,
          descricaoPasso: this.descricaoPassoApuracaoSelecionadas,
        };
      } else {
        this.indAcoesComNdsPendentes = false;
        this.filtros = {
          status: this.status,
          idTipoAcao: this.tiposAcao,
          filtro: this.filtro,
          nomesTiposUso: this.tiposUso,
          idGrupoFornecedor: this.fornecedores,
          tiposCalculo: this.tiposCalculo,
          meses: this.date,
          indAcoesComTeto: this.indAcoesComTeto ? true : null,
          codigoCampanhas: this.codigoCampanhasSelecionadas,
          descricaoPasso: this.descricaoPassoApuracaoSelecionadas,
        };
      }
      this.emitirPesquisa(this.filtros);
      this.salvarFiltrosWorkspace(this.filtros);
      this.menu = false;
    },
    emitirPesquisa(filtros) {
      this.$emit('ApuracaoAcaoFiltro__AplicaFiltros', filtros);
    },
    verificaStatusWorkspace(valor) {
      if (valor) {
        this.habilitaFlagBadge(true);
        this.status = this.statusPreSelecionado;
        this.pesquisar();
      } else {
        this.status = [];
        this.verificaCamposFiltrosPreenchidos(valor);
        this.pesquisar();
      }
    },
    verificaCamposFiltrosPreenchidos(valor) {
      if (this.inicializacaoComponente) {
        const workspaceFiltro = this.workspace.filtros;
        delete workspaceFiltro.status;
        this.filtrosVerificacao = Object.values(workspaceFiltro);
        this.inicializacaoComponente = false;
      } else {
        const filtroPreenchido = this.filtros;
        delete filtroPreenchido.status;
        this.filtrosVerificacao = Object.values(filtroPreenchido);
      }
      if (!this.filtrosVerificacao.length && !valor) {
        this.habilitaFlagBadge(false);
      } else {
        this.filtrosVerificacao.forEach((filtro) => {
          if (filtro != null && filtro.length) {
            this.countFiltrosPreenchidos += 1;
          }
        });
        if (this.countFiltrosPreenchidos === 0) {
          this.habilitaFlagBadge(false);
        } else {
          this.countFiltrosPreenchidos = 0;
        }
      }
    },
    carregarFiltros() {
      Promise.all([
        this.buscarGruposFornecedores(),
        this.buscarTiposAcao(),
        this.buscarTiposUso(),
        this.buscarCodigoCampanhas(),
        this.buscarDescricaoPassoApuracao(),
      ]).then(() => {
        if (this.$route.params && this.$route.params.filtrosAplicados) {
          this.carregarFiltrosParams();
        } else {
          this.carregarWorkspaceFiltros();
        }
      });
    },
    buscarGruposFornecedores(filtro = null) {
      const value = {
        tamanhoPagina: 100000,
        idNivelExtensao: 3,
        nome: filtro,
      };
      return this.extensaoResources.listarAtivos(value).then((resultado) => {
        resultado.data.resposta.forEach((el) => {
          const nomeFornecedor = el.id.toString().concat(' - ').concat(el.idExterno);
          const item = { shortCode: el.id, text: nomeFornecedor };
          this.itemsFornecedor.push(item);
        });
      });
    },
    buscarTiposAcao() {
      this.tipoAcaoResource.buscarTodosTiposAcao({ somenteAcoesAvulsas: true })
        .then((res) => {
          res.data.forEach((el) => {
            const item = { shortCode: el.id, text: el.nome };
            this.itemsTipoAcao.push(item);
          });
        });
    },
    buscarTiposUso() {
      return this.workflowAcaoMagaluResource
        .buscarTiposUso()
        .then((res) => {
          res.data.forEach((el) => {
            const item = { shortCode: el.id, text: el.nome };
            this.itemsTipoUso.push(item);
          });
        })
        .catch(() => {
          this.$toast(this.$tc('errors.acao.erro_carregar_dados'));
        });
    },
    buscarCodigoCampanhas() {
      return this.apuracaoAcaoListagemResource
        .buscarCodigoCampanhas()
        .then((res) => {
          res.data.forEach((el) => {
            const item = { text: el.cod_campanha };
            this.codigoCampanhas.push(item);
          });
        })
        .catch(() => {
          this.$toast(this.$tc('errors.acao.erro_carregar_dados'));
        });
    },
    buscarDescricaoPassoApuracao() {
      return this.workflowAcaoMagaluResource
        .buscarPassosDescricaoApuracao()
        .then((res) => {
          res.data.forEach((el) => {
            const item = { text: el.descricao };
            this.descricaoPasso.push(item);
          });
        })
        .catch(() => {
          this.$toast(this.$tc('errors.acao.erro_carregar_dados'));
        });
    },
    carregarFiltroMesesPadrao() {
      this.date = [];
      for (let i = 2; i >= 0; i -= 1) {
        const mes = moment().subtract(i, 'months');
        this.date.push(mes.format('YYYY-MM'));
      }
    },
    carregarWorkspaceFiltros() {
      const entidade = 'apuracao_acao';
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          if (Object.keys(this.workspace).length) {
            this.preencheFiltrosCarregamento(this.workspace.filtros);
            this.filtroPreenchido = Object.values(this.workspace.filtros);
          } else {
            this.preencheFiltrosCarregamento({});
          }

          if (this.filtroPreenchido && this.filtroPreenchido.length > 0) {
            this.montaBadge(this.filtroPreenchido, this.workspace.filtros.indAcoesComTeto, this.workspace.filtros.indAcoesComNdsPendentes);
            if (this.filtroPreenchido.length !== this.countFiltro) {
              this.habilitaFlagBadge(true);
            }
            this.countFiltro = 0;
          }
          if (!Object.keys(this.workspace).length && (!this.date || !this.date.length)) {
            this.carregarFiltroMesesPadrao();
            const filtrosAplicados = {
              meses: this.date,
            };
            this.emitirPesquisa(filtrosAplicados);
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    carregarFiltrosParams() {
      const { filtrosAplicados } = this.$route.params;

      if (Object.keys(filtrosAplicados).length) {
        this.preencheFiltrosCarregamento(filtrosAplicados);
        this.filtroPreenchido = Object.values(filtrosAplicados);
      }
      if (this.filtroPreenchido && this.filtroPreenchido.length > 0) {
        this.montaBadge(this.filtroPreenchido, filtrosAplicados.indAcoesComTeto, filtrosAplicados.indAcoesComNdsPendentes);
        if (this.filtroPreenchido.length !== this.countFiltro) {
          this.habilitaFlagBadge(true);
        }
        if (filtrosAplicados.status && (filtrosAplicados.status.length !== this.statusPreSelecionado.length
          || !filtrosAplicados.status.every((element, index) => element === this.statusPreSelecionado[index]))) {
          this.indAcoesComNdsPendentes = false;
        }
        this.countFiltro = 0;
      }
    },
    preencheFiltrosCarregamento(filtros) {
      this.status = filtros.status;
      this.tiposAcao = filtros.idTipoAcao;
      this.filtro = filtros.filtro;
      this.tiposUso = filtros.nomesTiposUso;
      this.fornecedores = filtros.idGrupoFornecedor;
      this.tiposCalculo = filtros.tiposCalculo;
      this.date = filtros.meses;
      this.indAcoesComTeto = filtros.indAcoesComTeto;
      this.codigoCampanhasSelecionadas = filtros.codigoCampanhas;
      this.descricaoPassoApuracaoSelecionadas = filtros.descricaoPasso;

      if (!this.date || !this.date.length) {
        this.carregarFiltroMesesPadrao();
      }

      const filtrosAplicados = {
        status: this.status,
        idTipoAcao: this.tiposAcao,
        filtro: this.filtro,
        nomesTiposUso: this.tiposUso,
        idGrupoFornecedor: this.fornecedores,
        tiposCalculo: this.tiposCalculo,
        meses: this.date,
        indAcoesComTeto: this.indAcoesComTeto,
        codigoCampanhas: this.codigoCampanhasSelecionadas,
        descricaoPasso: this.descricaoPassoApuracaoSelecionadas,
      };

      this.emitirPesquisa(filtrosAplicados);
    },
    salvarFiltrosWorkspace(filtros) {
      this.workspace.filtros = filtros;
      this.habilitaBadge(filtros);
      this.workspace.filtros.status = this.statusPreSelecionado;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    habilitaBadge(filtros) {
      this.filtroPreenchido = Object.values(filtros);
      if (this.filtroPreenchido.length !== 0) {
        this.montaBadge(this.filtroPreenchido, filtros.indAcoesComTeto, filtros.indAcoesComTeto);
        if (this.filtroPreenchido.length === this.countFiltro) {
          this.filtroPreenchido = [];
        }
        this.countFiltro = 0;
        if (this.filtroPreenchido.length === 0) {
          this.habilitaFlagBadge(false);
        } else {
          this.habilitaFlagBadge(true);
        }
      } else {
        this.habilitaFlagBadge(false);
      }
    },
    montaBadge(filtroPreenchido, indAcoesComTeto, indAcoesComNdsPendentes) {
      filtroPreenchido.forEach((item) => {
        if (!item || !item.length || item.indAcoesComTeto) {
          this.countFiltro += 1;
        }
      });
      if (indAcoesComTeto || indAcoesComNdsPendentes) {
        this.countFiltro -= 1;
      }
    },
    habilitaFlagBadge(habilitaIconBadge) {
      this.habilitaIconBadge = habilitaIconBadge;
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'apuracao_acao';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'apuracao_acao';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.carregarFiltros();
    if (this.indAcoesComNdsPendentes) {
      this.habilitaFlagBadge(true);
      this.inicializacaoComponente = true;
    }
  },
};
</script>
